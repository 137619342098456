var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter-container"},[_c('el-form',{staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"inline":""}},[_c('el-form-item',[_c('el-button',{attrs:{"size":"medium","type":"primary","icon":"el-icon-plus"},on:{"click":_vm.add}},[_vm._v("新增")])],1)],1)],1),_c('el-table',{attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"name","label":"徽章名字","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"imgUrl","label":"徽章图片","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"100px","height":"50px"},attrs:{"src":row.imgUrl,"preview-src-list":[row.imgUrl]}})]}}])}),_c('el-table-column',{attrs:{"prop":"content","label":"徽章说明","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"total","label":"徽章总数量","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"surplus","label":"徽章剩余数量","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-row',[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.edit(row.id)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除")])],1)]}}])})],1),_c('div',{staticClass:"page-container"},[_c('el-pagination',{attrs:{"background":"","total":_vm.count,"page-sizes":_vm.pageSizes,"page-size":_vm.pageSize,"current-page":_vm.page,"layout":"total, sizes, prev, pager, next, jumper"},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"size-change":_vm.refresh,"current-change":function (val) {
        _vm.page = val
        _vm.getList()
      }}})],1),_c('Form',{ref:"form"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }